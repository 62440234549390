import React, { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './LogoScreen.css';
import translations from '../utils/translations.json';

const LogoScreen = () => {
  const navigate = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.search);


    // Autenticação do Facebook (mobile) e Canva (todos os dispositivos)
    const facebookToken = hashParams.get('code');
    const canvaCode = queryParams.get('code');
    const params = queryParams.get('state');
    const parts = params.split(':');


    if (parts[0] === 'Instagram') {

      const totenIndex = parts[1];
      const activeTotenIndex = parts[2];

      exchangeCodeForToken(facebookToken, totenIndex, activeTotenIndex);

      // handleFacebookAuthentication(facebookToken, totenIndex, activeTotenIndex);

    } else {

      //console.log("Recuperado - UserID:", userId);
      const userId = parts[0];
      const language = parts[1];
      const thirdPart = parts?.[2] || null;

      let servidor = thirdPart ? process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL_PRODUCTION;

      handleCanvaAuthentication(canvaCode, userId, language, servidor);

    }

  }, [navigate]);


  const sendFrontendLog = async ({ message, step, userId, additionalInfo, severity = 'INFO' }) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/frontendLog`, {
            message,
            step,
            userId,
            additionalInfo,
            severity,
            source: 'frontend',
        });
    } catch (error) {
        console.error('Failed to send frontend log:', error);
    }
};


  const exchangeCodeForToken = async (code, totenIndex, activeTotenIndex) => {

    const userId = localStorage.getItem('userId');
    const language = localStorage.getItem('language');


    const t = (screen, key) => translations[language][screen][key];


    await sendFrontendLog({
      message: 'Exchanging code for Instagram access token',
      step: 'exchange_code_for_token',
      userId,
      additionalInfo: { totenIndex },

  });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/convert-instagram-token`, {
        code: code,
        redirectUri: process.env.REACT_APP_INSTAGRAM_URL_REDIRECT,
        userId: userId,
        totenIndex: totenIndex
      });


      await sendFrontendLog({
        message: 'Received response from backend for token exchange',
        step: 'receive_token_exchange_response',
        userId,
        additionalInfo: { responseData: response.data },
    });


      const { data } = response;

      if (data && data.tokenExpiry) {

        if (!isMobile) {

          window.opener && window.close();

        } else {

          const instagramUsername = data.instagramUsername ? `@${data.instagramUsername}` : '';

          let connectionSuccessMessage = t('profile', 'instagramConnectionSuccess');
          connectionSuccessMessage = connectionSuccessMessage.replace('{{username}}', instagramUsername);

          if (totenIndex === activeTotenIndex) {

            localStorage.setItem('tokenExpiry', data.tokenExpiry);

          }

          Swal.fire({
            title: t('profile', 'successTitle'),
            text: connectionSuccessMessage, // Texto de sucesso com o nome do usuári,
            icon: 'success',
            customClass: {
              popup: 'my-swal-alert',
              title: 'myCustomTitle'
            },
            confirmButtonText: t('profile', 'confirmButtonText')  // profile and logscreen in this case has the same massage.
          })

          navigate('/viewuser');

        }

      } else {

        if (!isMobile) {

          window.opener && window.close();

        } else {

          console.error('Nenhuma conta do Instagram retornada:', data);

          Swal.fire({
            title: t('profile', 'errorTitle'),
            text: t('profile', 'noInstagramAccountError'),
            icon: 'error',
            customClass: {
              popup: 'my-swal-alert',
              title: 'myCustomTitle'
            },
            confirmButtonText: t('profile', 'confirmButtonText')
          })

          navigate('/viewuser');

        }

      }

    } catch (error) {

      const errorMessage = error.response?.data?.error || t('profile', 'instagramConnectionError');
      const isInvalidAccountType = error.response?.data?.error === 'invalid_account_type';

      console.error(t('profile', 'instagramConnectionErrorLog'), error);

      Swal.fire({
        title: t('profile', 'errorTitle'),
        text: isInvalidAccountType ? t('profile', 'invalidAccountTypeError') : errorMessage,
        icon: 'error',
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        },
        confirmButtonText: t('profile', 'confirmButtonText')

      });

      navigate('/viewuser');
    }
  };


  /* const handleFacebookAuthentication = (facebookToken, totenIndex, activeTotenIndex) => {

    const userId = localStorage.getItem('userId');
    const language = localStorage.getItem('language');

    const t = (screen, key) => translations[language][screen][key];

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/convert-token`, {

      shortTermToken: facebookToken,
      userId: userId,
      totenIndex: totenIndex,
      action: ''

    }).then(response => {
      const { data } = response;
      if (data && data.instagramAccounts) {

        handleInstagramAccountSelection(data.instagramAccounts, totenIndex, userId, data.tokenExpiry, activeTotenIndex);

      }
    }).catch(error => {
      console.error('Erro ao conectar com o Instagram:', error);
      Swal.fire({
        title: t('logoScreen', 'instagramConnectionErrorTitle'),
        text: t('logoScreen', 'instagramConnectionErrorText'),
        icon: 'error', customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        },
        confirmButtonText: 'Ok'
      });
    });
  }; */


  /*const handleInstagramAccountSelection = (instagramAccounts, index, userId, tokenExpiry, activeTotenIndex) => {

    if (instagramAccounts.length === 1) {

      // Se houver apenas uma conta, prossiga diretamente
      completeConnection(instagramAccounts[0].id, instagramAccounts[0].pageId, index, userId, tokenExpiry, activeTotenIndex);

    } else {

      // Caso haja múltiplas contas, apresente um popup para o usuário escolher

    
      Swal.fire({
        title: 'Selecione uma conta do Instagram',
        html: `
          <div class="scrollable-container-pop-up">
            ${instagramAccounts.map((account, index) => `
              <div class="account-item">
                <img src="${account.picture}" alt="${account.username}" class="account-image" />
                <span class="account-username">${account.username}</span>
                <input type="radio" name="instagramAccount" value="${account.id}" ${index === 0 ? 'checked' : ''} class="account-radio" />
              </div>
            `).join('')}
          </div>
        `,
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        },
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowOutsideClick: true,
        heightAuto: false,
        didOpen: () => {

          const scrollableContainer = document.querySelector('.scrollable-container-pop-up');
          scrollableContainer.addEventListener('touchstart', (e) => {
            e.stopPropagation();
          }, { passive: true });

          
        },
        preConfirm: () => {
          const selectedAccount = document.querySelector('input[name="instagramAccount"]:checked');
          if (selectedAccount) {
            return selectedAccount.value;
          } else {
            Swal.showValidationMessage('Selecione uma conta');
            return false;
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // Usuário clicou em Confirmar
          const selectedAccount = instagramAccounts.find(acc => acc.id === result.value);
          completeConnection(selectedAccount.id, selectedAccount.pageId, index, userId, tokenExpiry, activeTotenIndex);
        } else if (result.dismiss === Swal.DismissReason.backdrop || result.dismiss === Swal.DismissReason.cancel) {
          // Usuário clicou em Cancelar
          deleteInstagramConnection(userId, index, activeTotenIndex);
        }
      });

    }
  }; */


  /*function deleteInstagramConnection(userId, index, activeTotenIndex) {

    // Limpar os campos relacionados ao token
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/convert-token`, {
      userId: userId,
      totenIndex: index,
      action: 'delete'
    }).then(response => {
      // Remove o token de expiração do localStorage 

      if (index === activeTotenIndex) {

        localStorage.removeItem('tokenExpiry');

      }

      navigate('/viewuser');

    }).catch(error => {
      console.error('Erro ao excluir a conexão:', error);
    });

  }; 

  const completeConnection = (instagramAccountId, pageFacebookId, index, userId, tokenExpiry, activeTotenIndex) => {

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/complete-connection`, {

      userId: userId,
      totenIndex: index,
      instagramAccountId: instagramAccountId,
      pageFacebookId: pageFacebookId // Adiciona o ID da página do Facebook

    }).then(response => {
      const { data } = response;

      if (data && data.success) {


        Swal.fire({
          title: 'Conexão bem-sucedida!',
          text: 'A conta do Instagram foi conectada com sucesso.',
          icon: 'success',
          customClass: {
            popup: 'my-swal-alert',
            title: 'myCustomTitle'
          },
          confirmButtonText: 'OK'
        });

        if (index === activeTotenIndex) {

          localStorage.setItem('tokenExpiry', tokenExpiry);

        }

        navigate('/viewuser');
      }

    }).catch(error => {
      console.error('Erro ao completar a conexão:', error);
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possível completar a conexão com o Instagram.',
        icon: 'error',
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        },
        confirmButtonText: 'OK'
      });
    });
  }; */


  const handleCanvaAuthentication = (canvaCode, userId, language, servidor) => {

    const t = (screen, key) => translations[language][screen][key];


    console.log('DESCOBRINDO OS VALORES DAS VARIAVEIS: ');
    console.log(canvaCode);
    console.log(userId);


    fetch(`${servidor}/users/canvas-conexion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: canvaCode,
        userId: userId
      })
    })
      .then(response => {
        localStorage.removeItem('codeVerifier');// Limpar codeVerifier após uso
        Swal.fire({
          title: t('logoScreen', 'canvaConnectionSuccessTitle'),
          text: t('logoScreen', 'canvaConnectionSuccessText'),
          icon: 'success',
          customClass: {
            popup: 'my-swal-alert',
            title: 'myCustomTitle'
          },
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

            if (!isMobile) { // Desktop
              window.opener && window.close();
            } else { // Mobile

              if (servidor.includes('websummit')) {

                window.location.href = 'https://websummit.postsucesso.com.br/main';

              } else {

                window.location.href = 'https://app.postsucesso.com.br/main';
              }

            }
          }
        });
      }).catch(error => {
        console.error('Erro ao conectar com o Canva:', error);
        Swal.fire({
          title: t('logoScreen', 'canvaConnectionErrorTitle'),
          text: t('logoScreen', 'canvaConnectionErrorText'),
          icon: 'error',
          customClass: {
            popup: 'my-swal-alert',
            title: 'myCustomTitle'
          },
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

            if (!isMobile) { // Desktop
              window.opener && window.close();
            } else { // Mobile

              if (servidor.includes('websummit')) {

                window.location.href = 'https://websummit.postsucesso.com.br/main';

              } else {

                window.location.href = 'https://app.postsucesso.com.br/main';
              }

            }
          }
        });
      });
  };

  return (
    <div className="logo-container-form-screen">
      <img
        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
        alt="Logo do PostSucesso"
        className="logo-image-form-screen"
      />
    </div>
  );
};

export default LogoScreen;
